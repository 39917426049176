import $ from 'jquery';

const $navToggler = $('.nav-toggler')

function toggleMenu() {
    $(this).toggleClass('active');
    $('.header__nav').slideToggle(200);
}

$navToggler.click(toggleMenu);

$('.search-button').on('click', function () {
    if(!$navToggler.hasClass('active') && window.screen.width <= 1200) {
        $navToggler.click()
    }

    $('#catalog-dropdown-button').click()
    setTimeout(() => {
        $('#catalog-search-input').focus()
    }, 300)
})
