import $ from "jquery";

$(".top-slider").slick({
  slidesToShow: 1,
  arrows: true,
  dots: true,
});

$(document).ready(function () {
  $("#dots-container").append($(".top-slider .slick-dots"));
});
