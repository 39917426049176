import $ from 'jquery';

$('.zoom-products-slider').slick({
    slidesToShow: 4,
    dots: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

let currentSlideState = 0;

$('.zoom-products-slider').on('afterChange', function (event, slick, currentSlide) {
    if(currentSlideState !== currentSlide) {
        currentSlideState = currentSlide;
    } else {
        const slideCount = slick.slideCount;

        if (currentSlide === 0 && currentSlideState === 0) {
            $('.zoom-products-slider').slick('slickGoTo', slideCount - slick.options.slidesToShow);
        } else {
            $('.zoom-products-slider').slick('slickGoTo', 0);
        }
    }
});

