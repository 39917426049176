import $ from "jquery";

$(".blog-slider").slick({
    slidesToShow: 1,
    dots: true,
    arrows: true
});

$(document).ready(function () {
    $(".blog-slider").append($(".slick-dots"));
});
