import $ from 'jquery';
import 'ion-rangeslider';

const validateNumber = (value, minValue, maxValue) => {
    const regExpForNumberTest = /^\d+$/.test(value)

    if (regExpForNumberTest) {
        const numericValue = +value;

        if (numericValue >= minValue && numericValue <= maxValue) {
            return numericValue
        }
        return null
    }
    return null
}


const initFunction = function () {
    $('.range-slider-wrapper').each(function () {

        let rangeMaxValue = 10000
        let rangeMinValue = 1;

        const $this = $(this);
        const rangeFrom = $this.find('.range-from')
        const rangeTo = $this.find('.range-to')

        const rangeFromAttrValue = rangeFrom.attr('value')
        const rangeToAttrValue = rangeTo.attr('value')

        const setInputAttrValue = (from, to) => {
            if(from) {
                rangeFrom.attr('value', from)
            }
            if(to) {
                rangeTo.attr('value', to)
            }
        }

        rangeMinValue = rangeFrom.data('range')
        rangeMaxValue = rangeTo.data('range')

        let rangeFromInitValue = '0'
        let rangeToInitValue = '0'

        const rangeFromAttrValueNumber = Number(rangeFromAttrValue)
        const rangeToAttrValueNumber = Number(rangeToAttrValue)
        const rangeMinValueNumber = Number(rangeMinValue)
        const rangeMaxValueNumber = Number(rangeMaxValue)

        if (rangeFromAttrValueNumber || rangeFromAttrValueNumber === 0) {
            switch (true) {
                case rangeFromAttrValueNumber < rangeMinValueNumber:
                    rangeFromInitValue = rangeMinValue;
                    break;
                case rangeFromAttrValueNumber > rangeMaxValueNumber:
                    rangeFromInitValue = rangeMaxValue;
                    break;
                default:
                    rangeFromInitValue = rangeFromAttrValue;
            }
        }

        if (rangeToAttrValueNumber || rangeToAttrValueNumber === 0) {
            switch (true) {
                case rangeToAttrValueNumber < rangeMinValueNumber:
                    rangeToInitValue = rangeMinValue;
                    break;
                case rangeToAttrValueNumber > rangeMaxValueNumber:
                    rangeToInitValue = rangeMaxValue;
                    break;
                default:
                    rangeToInitValue = rangeToAttrValue;
            }
        }


        rangeFrom.val(rangeFromInitValue);
        rangeTo.val(rangeToInitValue);

        const rangeSlider = $this.find('.range-slider')

        rangeSlider.ionRangeSlider({
            type: 'double',
            min: rangeMinValue,
            max: rangeMaxValue,
            from: rangeFromInitValue,
            to: rangeToInitValue,
            skin: 'round',
            hide_min_max: true,
            hide_from_to: true,
            onChange: (data) => {
                rangeFrom.val(data.from)
                rangeTo.val(data.to)

                setInputAttrValue(data.from, data.to)
            },
        });

        const my_range = rangeSlider.data('ionRangeSlider');

        const syncRangeInputsUIWithData = () => {
            rangeFrom.val(my_range.result.from)
            rangeTo.val(my_range.result.to)

            setInputAttrValue(my_range.result.from, my_range.result.to)
        }

        const updateRangeInputValue = (e, valueName) => {
            const value = e.target.value
            const validatedValue = validateNumber(value, rangeMinValue, rangeMaxValue)

            if (validatedValue) {
                my_range.update({
                    [valueName]: +value,
                });
            }

            syncRangeInputsUIWithData()
        }

        const updateRangeInputValueByKey = (e, valueName, minValue = 0, maxValue = 0) => {
            const currentValueNumeric = Number(e.target.value)

            if (e.key === 'ArrowUp' && currentValueNumeric < maxValue) {
                my_range.update({
                    [valueName]: currentValueNumeric + 1,
                });
            }
            if (e.key === 'ArrowDown' && currentValueNumeric > minValue) {
                my_range.update({
                    [valueName]: currentValueNumeric - 1,
                });
            }

            syncRangeInputsUIWithData()
        }

        rangeTo.on('input', (e) => updateRangeInputValue(e, 'to'));
        rangeFrom.on('input', (e) => updateRangeInputValue(e, 'from'));

        rangeFrom.on('keydown', (e) => {
            updateRangeInputValueByKey(e, 'from', rangeMinValue, rangeMaxValue)
        });

        rangeTo.on('keydown', (e) => {
            updateRangeInputValueByKey(e, 'to', rangeMinValue, rangeMaxValue)
        });
    });
}

$(document).ready(initFunction)
