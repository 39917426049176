import { CountUp } from 'countup.js';

window.onload = function () {
  var options = {
    separator: ' ',
  };
  new CountUp('count-1', 3000, options).start();
  new CountUp('count-2', 22000, options).start();
  new CountUp('count-3', 900, options).start();
  new CountUp('count-4', 47262600, options).start();
};
